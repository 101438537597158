export const baseSize = 10;
export const space = {
  xxs: `.25em`,
  xs: `.5em`,
  s: `.75em`,
  m: `1em`,
  l: `1.5em`,
  xl: `2em`,
  xxl: `4em`,
  chonky: '12rem',
};
export const breakpoints = {
  baseMax: `${baseSize * 60 - 1}px`,
  s: `${baseSize * 60}px`,
  sMax: `${baseSize * 120 - 1}px`,
  m: `${baseSize * 120}px`,
  mMax: `${baseSize * 160 - 1}px`,
  l: `${baseSize * 160}px`,
}; //560, 1200, 1600
