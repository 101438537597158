import uniqueId from 'lodash/uniqueId';
import React from 'react';
import styled from 'styled-components';
import { TooltipPopup, TooltipWrapper } from './tooltip';

export const SelectionWrapper = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
    input {
      font-size: 125%;
    }
  }
`;

export const StyledSelect = styled.select`
  outline: 0;
  box-shadow: none;
  background: white;
  background-image: none;
  &::-ms-expand {
    display: none;
  }
  flex: 1;
  cursor: pointer;
  min-width: 200px;

  &:focus {
    border-bottom: 2px solid ${(p) => p.theme.bgColors.primary};
  }

  &:invalid {
    color: gray;
  }
  &:disabled {
    background: #ccc;
  }
  option {
    color: currentColor;
  }

  width: 100%;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.s}) {
    width: auto;
  }
`;

export const FileInput = styled.fieldset`
  /* hide the 'real' input */
  input[type='file'] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    white-space: nowrap;
  }

  label {
    font-size: 100%;
    line-height: 1.4;
    font: ${(p) => p.theme.fontFamilies.primary};
    padding: ${(p) => p.theme.space.s};
    border: 2px solid ${(p) => p.theme.fontColors.lightGray};
    border-bottom: 2px solid ${(p) => p.theme.bgColors.dark};
    color: ${(p) => p.theme.fontColors.dark};
    position: relative;
    background-color: white;
    cursor: pointer;
    display: block;
    width: 100%;
  }

  /* handle focus */
  input[type='file']:focus + label {
    outline: 2px solid ${(p) => p.theme.bgColors.secondary};
  }
  /* handle disabled */
  input[type='file']:disabled + label {
    color: #575757;
    background: #ddd;
    cursor: default;
  }
`;

export const CheckboxField = styled.fieldset`
  legend {
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  div {
    display: inline-block;
    margin-bottom: 1em;
    margin-right: 1em;
  }
`;

export const Checkbox = styled.div`
  /* hide the 'real' input */
  input[type='checkbox'],
  .disabled-input {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 2em;
    margin-bottom: 1em;
    cursor: pointer;
    font-size: 100%;
    line-height: 1.3;
    font-weight: 400;
  }

  /* set up the 'fake' input */
  label::before,
  label::after {
    position: absolute;
    content: '';
    border-radius: 1px;
  }
  label::before {
    height: 1.45em;
    width: 1.45em;
    border: 1px solid ${(p) => p.theme.fontColors.dark};
    padding-right: ${(p) => p.theme.space.m};
    background: white;
    left: 0;
  }
  input[type='checkbox']:checked + label::after {
    height: 0.6em;
    width: 0.9em;
    border-left: 3px solid ${(p) => p.theme.fontColors.dark};
    border-bottom: 3px solid ${(p) => p.theme.fontColors.dark};
    transform: rotate(-45deg);
    top: 0.25em;
    left: 0.25em;
  }

  /* handle focus */
  input[type='checkbox']:focus + label::before,
  .disabled-input:focus + label::before {
    outline: 2px solid ${(p) => p.theme.bgColors.secondary};
  }
  /* handle disabled */
  input[type='checkbox']:disabled + label,
  .disabled-input + label {
    color: #575757;
    cursor: default;
  }
  input[type='checkbox']:disabled + label::before,
  .disabled-input + label::before {
    background: #ddd;
  }

  :hover {
    label::before {
      border-color: ${(p) => p.theme.bgColors.secondary};
    }
    input[type='checkbox']:disabled + label::before,
    .disabled-input + label::before {
      border-color: ${(p) => p.theme.fontColors.dark};
    }
    input[type='checkbox']:checked + label::after {
      border-color: ${(p) => p.theme.bgColors.secondary};
    }
  }
`;

export const TableCheckbox = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    disabled?: boolean;
    indeterminate?: boolean;
    testId?: string;
    style?: React.CSSProperties;
  }
>(
  (
    { disabled = false, testId, indeterminate, label, style, ...rest },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const checkboxId = uniqueId();
    return (
      <Checkbox style={style}>
        <input
          id={checkboxId}
          ref={ref}
          type="checkbox"
          disabled={disabled}
          data-testid={testId}
          {...rest}
        />
        <label
          htmlFor={checkboxId}
          aria-label={`Select ${label}`}
          style={{ marginRight: 0, paddingLeft: '1.45em' }}
        />
      </Checkbox>
    );
  }
);

export const TableDisabledCheckboxWithTooltip = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    tooltipText: string;
    disabled?: boolean;
    indeterminate?: boolean;
    testId?: string;
  }
>(
  (
    { disabled = false, tooltipText, label, testId, indeterminate, ...rest },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const checkboxId = uniqueId();
    const tooltipId = uniqueId();
    return (
      <Checkbox>
        <TooltipWrapper>
          {disabled ? (
            <div
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
              aria-describedby={tooltipId}
              className="disabled-input"
            />
          ) : (
            <input
              id={checkboxId}
              ref={ref}
              type="checkbox"
              data-testid={testId}
              {...rest}
              aria-describedby={tooltipId}
            />
          )}
          <label
            htmlFor={checkboxId}
            aria-label={`Select `}
            style={{ marginRight: 0, paddingLeft: '1.45em' }}
          />
          {disabled && (
            <TooltipPopup role="tooltip" id={tooltipId}>
              {tooltipText}
            </TooltipPopup>
          )}
        </TooltipWrapper>
      </Checkbox>
    );
  }
);

export const RadioGroupFieldset = styled.fieldset`
  width: fit-content;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RadioGroupLegend = styled.legend`
  font-size: 100%;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 0.5em;
  font-weight: 400;
  input {
    margin: 0 0.5em 0 0;
  }
`;
