import React from 'react';
import appsettings, { set } from '../appsettings';

function BranchManagerToggle() {
  const [branchCode, setBranchCode] = React.useState(
    appsettings.branchOverride.branchCode ?? ''
  );

  React.useEffect(() => {
    set('branchOverride.branchCode', branchCode);
  }, [branchCode]);

  return (
    <>
      <label>
        Branch Code:{' '}
        <input
          type="text"
          value={branchCode}
          onChange={(e) => setBranchCode(e.target.value)}
        />
      </label>
    </>
  );
}

export default BranchManagerToggle;
