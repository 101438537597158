import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useAuthorize } from '../auth/authorize';
import Role from '../auth/roles';
import NoMatch from '../features/404';
import Home from '../features/home';
import Layout from '../features/layout';
import { useSmartLeadsSettings } from '../features/smartleads/shared/settings-context';
import { DetailedRouteObject } from './interfaces';
import { RouteDetailsProvider } from './useRouteDetails';

//admin
const AdminPage = React.lazy(() => import('../features/admin'));
const EmergencyBroadcast = React.lazy(
  () => import('../features/emergency-broadcast')
);
const AdminUserList = React.lazy(() => import('../features/user-list'));

//leaderboard
const Leaderboards = React.lazy(() => import('../features/leaderboards'));

//notifications
const NotificationSettings = React.lazy(
  () => import('../features/notifications')
);

//payments
const PaymentsUtilities = React.lazy(() => import('../features/payments'));
const SendGFDLink = React.lazy(() => import('../features/payments/gfd-link'));
//profile
const PersonalProfile = React.lazy(
  () => import('../features/personal-profile')
);
const PersonalProfileAdmin = React.lazy(
  () => import('../features/personal-profile/admin')
);

//onboarding
const SetupList = React.lazy(() => import('../features/onboarding/list'));
const SetupChecklist = React.lazy(
  () => import('../features/onboarding/checklist')
);
const TemplateData = React.lazy(
  () => import('../features/onboarding/templates')
);
const TemplatesList = React.lazy(
  () => import('../features/onboarding/templates/template-list')
);
const CreateNewProcess = React.lazy(
  () => import('../features/onboarding/new-process')
);

//forecasting
const BranchHistory = React.lazy(
  () => import('../features/forecasts/branch-history')
);
const BranchForecast = React.lazy(
  () => import('../features/forecasts/branch-forecasts')
);
const BranchList = React.lazy(
  () => import('../features/forecasts/branch-history/branch-list')
);
const ForecastReport = React.lazy(
  () => import('../features/forecasts/forecast-report')
);

//smartleads
const SmartLeadsData = React.lazy(() => import('../features/smartleads'));
const SmartLeadsLayout = React.lazy(
  () => import('../features/smartleads/layout')
);
const SmartLeadsSettings = React.lazy(
  () => import('../features/smartleads/settings')
);
const SmartLeadsDashboard = React.lazy(
  () => import('../features/smartleads/dashboard')
);
const SmartLeadsInbox = React.lazy(
  () => import('../features/smartleads/inbox')
);
const SmartLeadsPromotedLead = React.lazy(
  () => import('../features/smartleads/inbox/single-lead')
);
const SmartLeadsGroups = React.lazy(
  () => import('../features/smartleads/groups')
);
const SmartLeadsGroup = React.lazy(
  () => import('../features/smartleads/groups/single-group')
);
const SmartLeadsLists = React.lazy(
  () => import('../features/smartleads/lists')
);
const SmartLeadsList = React.lazy(
  () => import('../features/smartleads/lists/single-list')
);
const SearchContacts = React.lazy(
  () => import('../features/smartleads/contacts/search')
);
const SmartLeadsContact = React.lazy(
  () => import('../features/smartleads/contacts/single-contact')
);
const CreateSmartLeadsContact = React.lazy(
  () => import('../features/smartleads/contacts/new-contact')
);
const SmartLeadsDuplicateReport = React.lazy(
  () => import('../features/smartleads/duplicates')
);
const SurefireListManagement = React.lazy(
    () => import('../features/smartleads/surefire')
);

// ----- end imports -----
const Authorize: React.FC<{ roles?: Role[] }> = ({ roles, children }) => {
  const authorize = useAuthorize();
  const location = useLocation();

  if (authorize({})) return <>{children}</>;
  if (roles && authorize({ allowedRoles: roles })) return <>{children}</>;
  return <Navigate to="404" state={{ from: location }} replace />;
};

const SmartLeadsEnabled: React.FC = ({ children }) => {
  const location = useLocation();
  const { settings } = useSmartLeadsSettings();

  if (settings.smartLeadsEnabled) return <>{children}</>;
  return <Navigate to="settings" state={{ from: location }} replace />;
};

export const routes: DetailedRouteObject[] = [
  {
    element: (
      <RouteDetailsProvider>
        <Outlet />
      </RouteDetailsProvider>
    ),
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          // home
          {
            details: {
              title: 'Novus Apps',
              excludeFromBreadcrumbs: true,
            },
            index: true,
            element: <Home />,
          },

          // admin
          {
            details: {
              title: 'Admin Tools',
            },
            path: 'admin',
            element: (
              <Authorize>
                <AdminPage />
              </Authorize>
            ),
          },
          {
            details: {
              title: 'Emergency Broadcast',
            },
            path: 'broadcasts',
            element: (
              <Authorize>
                <EmergencyBroadcast />
              </Authorize>
            ),
          },
          {
            details: {
              title: 'User List',
            },
            path: '/user-list',
            element: (
              <Authorize
                roles={[
                  Role.BranchSupport,
                  Role.SmartLeadsAdmin,
                  Role.ProfileAdmin,
                ]}
              >
                <AdminUserList />
              </Authorize>
            ),
          },

          // leaderboards both branch and LO
          {
            details: {
              title: 'Leaderboards',
            },
            path: 'leaderboard',
            element: <Leaderboards />,
          },

          // notifications
          {
            details: {
              title: 'Notification Settings',
            },
            path: 'notifications',
            element: <NotificationSettings />,
            // children: [
            //   {
            //     title: 'Notification Settings',
            //     path: ':userId',
            //     element: (
            //         <Authorize roles={[Role.BranchSupport]}>
            //           <NotificationSettings />
            //         </Authorize>
            //     ),
            //   },
            // ],
          },

          // payments
          {
            path: '/payment-utilities',
            element: <Outlet />,
            children: [
              {
                details: {
                  title: 'Payment Utilities',
                },
                index: true,
                element: (
                  <Authorize roles={[Role.PaymentsAdmin]}>
                    <PaymentsUtilities />
                  </Authorize>
                ),
              },
              {
                details: {
                  title: 'Send GFD Link',
                },
                path: 'send-gfd-link',
                element: <SendGFDLink />,
              },
            ],
          },

          // profile
          {
            details: {
              title: 'Profile Administration',
            },
            path: 'profile-admin',
            element: (
              <Authorize roles={[Role.ProfileAdmin]}>
                <PersonalProfileAdmin />
              </Authorize>
            ),
          },
          {
            path: 'profile',
            element: <Outlet />,
            children: [
              {
                details: {
                  title: 'My Profile',
                  excludeWhenImpersonating: true,
                },
                index: true,
                element: <PersonalProfile />,
              },
              {
                details: {
                  title: 'User Profile',
                },
                path: ':userId',
                element: (
                  <Authorize roles={[Role.ProfileAdmin]}>
                    <PersonalProfile />
                  </Authorize>
                ),
              },
            ],
          },

          // onboarding
          {
            path: '/onboarding',
            element: <Outlet />,
            children: [
              {
                details: {
                  title: 'Onboarding',
                },
                index: true,
                element: (
                  <Authorize
                    roles={[
                      Role.OnboardingAdmin,
                      Role.OnboardingManager,
                      Role.OnboardingApprover,
                      Role.OnboardingHiringManager,
                    ]}
                  >
                    <SetupList />
                  </Authorize>
                ),
              },
              {
                details: {
                  title: 'Create New Onboarding Process',
                },
                path: 'new',
                element: (
                  <Authorize
                    roles={[
                      Role.OnboardingAdmin,
                      Role.OnboardingApprover,
                      Role.OnboardingHiringManager,
                    ]}
                  >
                    <CreateNewProcess />
                  </Authorize>
                ),
              },
              {
                details: {
                  title: 'Onboarding Checklist',
                },
                path: ':setupId',
                element: (
                  <Authorize
                    roles={[
                      Role.OnboardingAdmin,
                      Role.OnboardingManager,
                      Role.OnboardingApprover,
                    ]}
                  >
                    <SetupChecklist />
                  </Authorize>
                ),
              },
              {
                path: 'templates',
                element: <Outlet />,
                children: [
                  {
                    details: {
                      title: 'Onboarding Templates',
                    },
                    index: true,
                    element: (
                      <Authorize
                        roles={[Role.OnboardingAdmin, Role.OnboardingApprover]}
                      >
                        <TemplatesList />
                      </Authorize>
                    ),
                  },
                  {
                    details: {
                      title: 'Onboarding Template Details',
                    },
                    path: ':templateId',
                    element: (
                      <Authorize
                        roles={[Role.OnboardingAdmin, Role.OnboardingApprover]}
                      >
                        <TemplateData />
                      </Authorize>
                    ),
                  },
                ],
              },
            ],
          },

          // forecasting
          {
            path: 'forecasts',
            element: <Outlet />,
            children: [
              {
                details: {
                  title: 'Forecast Report',
                  excludeFromBreadcrumbs: true,
                },
                index: true,
                element: (
                  <Authorize roles={[Role.BranchSupport]}>
                    <ForecastReport />
                  </Authorize>
                ),
              },
              {
                details: {
                  title: 'Forecast Report',
                  excludeFromBreadcrumbs: true,
                },
                path: ':date',
                element: (
                  <Authorize roles={[Role.BranchSupport]}>
                    <ForecastReport />
                  </Authorize>
                ),
              },
              {
                path: 'branches',
                element: <Outlet />,
                children: [
                  {
                    details: {
                      title: 'Branch List - Forecast History',
                    },
                    path: 'history',
                    element: (
                      <Authorize
                        roles={[Role.BranchManager, Role.BranchSupport]}
                      >
                        <BranchList />
                      </Authorize>
                    ),
                  },
                  {
                    details: {
                      title: 'Branch Forecast History',
                    },
                    path: 'history/:branchCode',
                    element: (
                      <Authorize
                        roles={[Role.BranchManager, Role.BranchSupport]}
                      >
                        <BranchHistory />
                      </Authorize>
                    ),
                  },
                  {
                    details: {
                      title: 'Branch List - Current Forecasts',
                      excludeFromBreadcrumbs: true,
                    },
                    path: 'current',
                    element: (
                      <Authorize
                        roles={[Role.BranchManager, Role.BranchSupport]}
                      >
                        <BranchList />
                      </Authorize>
                    ),
                  },
                  {
                    path: 'current/:branchCode',
                    details: {
                      title: 'Branch Forecast',
                    },
                    element: (
                      <Authorize
                        roles={[Role.BranchManager, Role.BranchSupport]}
                      >
                        <BranchForecast />
                      </Authorize>
                    ),
                  },
                ],
              },
            ],
          },

          // Temporarily disabled as of August 2024
          // smartleads 
          // {
          //   path: 'smartleads',
          //   element: (
          //     <Authorize roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}>
          //       <SmartLeadsData>
          //         <SmartLeadsLayout>
          //           <Outlet />
          //         </SmartLeadsLayout>
          //       </SmartLeadsData>
          //     </Authorize>
          //   ),
          //   children: [
          //     {
          //       details: {
          //         title: 'SmartLeads Dashboard',
          //         excludeFromBreadcrumbs: true,
          //       },
          //       index: true,
          //       element: (
          //         <Authorize
          //           roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //         >
          //           <SmartLeadsEnabled>
          //             <SmartLeadsDashboard />
          //           </SmartLeadsEnabled>
          //         </Authorize>
          //       ),
          //     },
          //     {
          //       details: {
          //         title: 'SmartLeads Settings',
          //       },
          //       path: 'settings',
          //       element: <SmartLeadsSettings />,
          //     },
          //     {
          //       path: 'inbox',
          //       element: <Outlet />,
          //       children: [
          //         {
          //           details: {
          //             title: 'SmartLeads Promoted Leads',
          //           },
          //           index: true,
          //           element: (
          //             <Authorize
          //               roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //             >
          //               <SmartLeadsEnabled>
          //                 <SmartLeadsInbox />
          //               </SmartLeadsEnabled>
          //             </Authorize>
          //           ),
          //         },
          //         {
          //           details: {
          //             title: 'Lead',
          //           },
          //           path: ':leadId',
          //           element: (
          //             <Authorize
          //               roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //             >
          //               <SmartLeadsEnabled>
          //                 <SmartLeadsPromotedLead />
          //               </SmartLeadsEnabled>
          //             </Authorize>
          //           ),
          //         },
          //       ],
          //     },
          //     {
          //       details: {
          //         title: 'SmartLeads Partners',
          //       },
          //       path: 'groups',
          //       element: (
          //         <Authorize
          //           roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //         >
          //           <SmartLeadsEnabled>
          //             <SmartLeadsGroups />
          //           </SmartLeadsEnabled>
          //         </Authorize>
          //       ),
          //     },
          //     {
          //       details: {
          //         title: 'SmartLeads Lists',
          //       },
          //       path: 'lists',
          //       element: (
          //         <Authorize
          //           roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //         >
          //           <SmartLeadsEnabled>
          //             <SmartLeadsLists />
          //           </SmartLeadsEnabled>
          //         </Authorize>
          //       ),
          //     },
          //     {
          //       path: 'contacts',
          //       element: <Outlet />,
          //       children: [
          //         {
          //           details: {
          //             title: 'SmartLeads Contacts',
          //           },
          //           index: true,
          //           element: (
          //             <Authorize
          //               roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //             >
          //               <SmartLeadsEnabled>
          //                 <SearchContacts />
          //               </SmartLeadsEnabled>
          //             </Authorize>
          //           ),
          //         },
          //         {
          //           details: {
          //             title: 'SmartLeads Contact',
          //           },
          //           path: 'new',
          //           element: (
          //             <Authorize
          //               roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //             >
          //               <SmartLeadsEnabled>
          //                 <CreateSmartLeadsContact />
          //               </SmartLeadsEnabled>
          //             </Authorize>
          //           ),
          //         },
          //         {
          //           details: {
          //             title: 'Contact',
          //           },
          //           path: ':contactId',
          //           element: (
          //             <Authorize
          //               roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //             >
          //               <SmartLeadsEnabled>
          //                 <SmartLeadsContact />
          //               </SmartLeadsEnabled>
          //             </Authorize>
          //           ),
          //         },
          //       ],
          //     },
          //     {
          //       details: {
          //         title: 'SmartLeads Duplicate Report',
          //       },
          //       path: 'duplicates',
          //       element: (
          //         <Authorize
          //           roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //         >
          //           <SmartLeadsEnabled>
          //             <SmartLeadsDuplicateReport />
          //           </SmartLeadsEnabled>
          //         </Authorize>
          //       ),
          //     },
          //     {
          //       path: ':groupId',
          //       element: <Outlet />,
          //       children: [
          //         {
          //           details: {
          //             title: 'SmartLeads Partner',
          //           },
          //           index: true,
          //           element: (
          //             <Authorize
          //               roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //             >
          //               <SmartLeadsEnabled>
          //                 <SmartLeadsGroup />
          //               </SmartLeadsEnabled>
          //             </Authorize>
          //           ),
          //         },
          //         {
          //           path: ':listId',
          //           element: <Outlet />,
          //           children: [
          //             {
          //               details: {
          //                 title: 'SmartLeads List',
          //               },
          //               index: true,
          //               element: (
          //                 <Authorize
          //                   roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //                 >
          //                   <SmartLeadsEnabled>
          //                     <SmartLeadsList />
          //                   </SmartLeadsEnabled>
          //                 </Authorize>
          //               ),
          //             },
          //             {
          //               path: ':contactId',
          //               details: {
          //                 title: 'SmartLeads Contact',
          //               },
          //               element: (
          //                 <Authorize
          //                   roles={[Role.SmartLeadsAdmin, Role.SmartLeadsUser]}
          //                 >
          //                   <SmartLeadsEnabled>
          //                     <SmartLeadsContact />
          //                   </SmartLeadsEnabled>
          //                 </Authorize>
          //               ),
          //             },
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // 404
          {
            details: { title: 'Not Found', excludeFromBreadcrumbs: true },
            path: '*',
            element: <NoMatch />,
          },
        ],
      },
    ],
  },
];
