import React from 'react';
import styled from 'styled-components';

const mainId = 'main-content';

export const SkipNavLink = ({ children = 'Skip to content', ...props }) => (
  <SkipLink {...props} href={`#${mainId}`}>
    {children}
  </SkipLink>
);

const SkipLink = styled.a`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  background: white;
  border-radius: 0.2em;
  box-shadow: 0.25em 0.25em 0.75em #555555aa;
  color: ${(p) => p.theme.bgColors.dark};
  font-weight: 700;
  text-decoration: none;
  z-index: 1000;
  :focus {
    clip: auto;
    height: auto;
    left: 1em;
    top: 1em;
    padding: 1em;
    position: fixed;
    width: auto;
    height: auto;
  }
`;
