import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Status, ToastOutput, useToast } from '../../context/toast-context';
import { SubtleButton } from '../dumb/buttons-links';
import { Alert } from '../dumb/typography';

export const ToastContainer = () => {
  const { toasts, message } = useToast();
  return createPortal(
    <StyledToastContainer>
      <div role="alert" aria-live="assertive" className="visually-hidden">
        {message}
      </div>
      {toasts.map((t) => (
        <Toast key={t.id} content={t} />
      ))}
    </StyledToastContainer>,
    document.body
  );
};

const Toast: React.FC<{ content: ToastOutput }> = ({ content }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      removeToast(content.id);
    }, 16000);

    return function cleanup() {
      window.clearTimeout(timeoutId);
    };
  }, [content.id, removeToast]);

  return (
    <StyledToastItem status={content.status} role="alert" aria-live="assertive">
      <ToastCloseButton onClick={() => removeToast(content.id)} aria-hidden>
        <FontAwesomeIcon icon={faTimes} />
      </ToastCloseButton>
      <div>{content.message}</div>
    </StyledToastItem>
  );
};

const StyledToastContainer = styled.div`
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 1000;
  max-height: calc(100vh - 2em);
  overflow-y: auto;
`;

const StyledToastItem = styled.div<{ status: Status }>`
  margin: 1em;
  width: 300px;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1em;
  color: white;
  font-weight: 700;
  background-color: ${(p) =>
    p.status === 'fulfilled'
      ? p.theme.fontColors.primary
      : p.theme.fontColors.warn};
  box-shadow: 2px 2px 6px ${(p) => p.theme.bgColors.darkGray};
`;

const ToastCloseButton = styled(SubtleButton)`
  margin: 0 0.5em 0 0;
  padding: 0;
  &:hover {
    color: white;
  }
`;

const InlineToastContainer = styled.div`
  color: ${(p) => p.color};
  font-weight: 700;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    font-size: 90%;
  }
  margin-top: 1em;
  min-height: 1em;
`;

interface Rejectedp extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
}

export const InlineRejectedToast: React.FC<Rejectedp> = (p) => (
  <InlineToastContainer
    role="alert"
    aria-live="assertive"
    {...p}
    className={
      p.message.length > 0 ? p.className : `${p.className} visually-hidden`
    }
  >
    <Alert>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      {p.message}
    </Alert>
  </InlineToastContainer>
);
