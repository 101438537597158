import React from 'react';
import appsettings, { set } from '../appsettings';

function TestMessageToggle() {
  const [testMessage, setTestMessage] = React.useState(
    appsettings.toggles.printTestMessage
  );

  React.useEffect(() => {
    set('toggles.printTestMessage', testMessage);
  }, [testMessage]);

  return (
    <div>
      <label>
        Print Test Message{' '}
        <input
          type="checkbox"
          checked={testMessage}
          onChange={(e) => setTestMessage(e.target.checked)}
        />
      </label>
    </div>
  );
}

export default TestMessageToggle;
