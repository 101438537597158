import intersection from 'lodash/intersection';
import { UserContextData, useUser } from '../context/user-context';
import Role from './roles';

interface AuthorizeTest {
  allowedRoles?: Role[];
}

export const authorize = (
  user: UserContextData | null,
  { allowedRoles }: AuthorizeTest
): boolean => {
  return (
    intersection(
      user?.roles || [],
      // The Administrator role is implicitly allowed for everything
      [Role.Administrator, ...(allowedRoles || [])]
    ).length > 0
  );
};

export const useAuthorize = () => {
  const user = useUser();
  return (test: AuthorizeTest) => authorize(user, test);
};
