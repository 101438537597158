import React from 'react';
import { useFetch } from 'react-async';

const MigrationsTool: React.FC<{
  setAlert: (active: boolean) => void;
}> = ({ setAlert }) => {
  const headers = { Accept: 'application/json' };
  const getPending = useFetch<string[]>(
    '/devtools/migrations',
    { headers },
    {
      onResolve: (migrations) => setAlert(migrations.length > 0),
    }
  );
  const apply = useFetch(
    '/devtools/migrations/apply',
    { method: 'POST' },
    {
      onResolve: () => getPending.reload(),
    }
  );

  return (
    <div>
      {getPending.isResolved && getPending.data && getPending.data.length > 0 && (
        <div style={{ border: '2px solid gold', padding: '4px 12px' }}>
          ⚠ Pending DB Migrations: {getPending.data.length}
          <button onClick={() => apply.run()}>Apply</button>
        </div>
      )}
      {/* Only error in development mode because other envs will return a 404 */}
      {getPending.isRejected && process.env.NODE_ENV === 'development' && (
        <div style={{ border: '2px solid red', padding: '4px 12px' }}>
          Error checking DB Migrations! Check console for details.
        </div>
      )}
    </div>
  );
};

export default MigrationsTool;
