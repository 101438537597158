import React from 'react';

const ViewportContext = React.createContext({
  viewPortWidth: 0,
  viewPortHeight: 0,
  currentScroll: 0,
});

const ViewportProvider: React.FC = ({ children }) => {
  const [width, setWidth] = React.useState(
    document.documentElement.clientWidth
  );
  const [height, setHeight] = React.useState(
    document.documentElement.clientHeight
  );
  const [currentScroll, setCurrentScroll] = React.useState(window.pageYOffset);

  const handleWindowResize = () => {
    setWidth(document.documentElement.clientWidth);
    setHeight(document.documentElement.clientHeight);
  };

  const handleWindowScroll = () => {
    setCurrentScroll(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  return (
    <ViewportContext.Provider
      value={{
        viewPortWidth: width,
        viewPortHeight: height,
        currentScroll: currentScroll,
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
};

const useViewport = () => {
  return React.useContext(ViewportContext);
};

export { ViewportProvider, useViewport };
