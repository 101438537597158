import { DefaultTheme } from 'styled-components';
import icon from '../assets/logos/Novus_icon.svg';
import logo from '../assets/logos/Novus_logo.svg';
import logoWhite from '../assets/logos/Novus_logo_white.svg';
import { baseSize, breakpoints, space } from './theme-constants';

const _novusBlue = 'rgb(0, 46, 93)'; // WCAG AA all text (white), graphical objects & UI component
const _novusBlueHover = 'rgb(2, 60, 119)'; // WCAG AA all text (white), graphical objects & UI component
const _novusGreen = 'rgb(34, 135, 34)'; // WCAG AA all text (white), graphical objects & UI component
const _novusGreenHover = 'rgb(10, 85, 10)'; // WCAG AA all text (white), graphical objects & UI component
const _novusGreenOnDark = 'rgb(72, 173, 72)';
const _novusBrightBlue = 'rgb(0, 115, 230)'; // WCAG AA all text (white), graphical objects & UI component
const _novusOrange = 'rgb(236, 113, 19)'; // WCAG AA Large text (white), graphical objects & UI component
const _darkOrange = 'rgb(185, 62, 0)'; // WCAG AA all text (on white), graphical objects & UI component
const _darkGray = 'rgb(60, 63, 77)';
const _medGray = 'rgb(210, 210, 210)';
const _lightGray = 'rgb(240, 240, 240)';

export const novusTheme: DefaultTheme = {
  baseSize: baseSize,
  space: space,
  breakpoints: breakpoints,
  bgColors: {
    dark: _novusBlue,
    primary: _novusGreen,
    secondary: _novusBrightBlue,
    tertiary: _novusOrange,
    darkGray: _darkGray,
    medGray: _medGray,
    lightGray: _lightGray,
  },
  navColors: {
    dark: _novusBlue,
    darkHover: _novusBlueHover,
    primary: _novusGreen,
    primaryHover: _novusGreenHover,
  },
  fontColors: {
    dark: _novusBlue,
    primary: _novusGreen,
    secondary: _novusBrightBlue,
    lightGray: _lightGray,
    darkGray: _darkGray,
    warn: _darkOrange,
    highlight: _novusGreenOnDark,
  },
  fontFamilies: {
    primary:
      "'Mark Pro', 'Source Sans Pro', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    secondary:
      "'IBM Plex Sans', 'Source Sans Pro', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  },
  logo: logo,
  logoWhite: logoWhite,
  icon: icon,
  title: 'Novus Apps',
  name: 'Novus Home Mortgage',
  favicons: {
    appleTouchIcon: '/apple-touch-icon.png',
    icon32: '/favicon-32x32.png',
    icon16: '/favicon-16x16.png',
    manifest: '/site.webmanifest',
    maskIcon: '/safari-pinned-tab.svg',
    shortcutIcon: '/favicon.ico',
    msAppColor: '#2b5797',
    msAppConfig: '/browserconfig.xml',
  },
};
