import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Button } from '../../components/dumb/buttons-links';
import { useImpersonationContext } from '../../context/impersonation-context';

const ImpersonationControl = () => {
  const navigate = useNavigate();
  const { impersonating, currentUser, setImpersonatedUser } =
    useImpersonationContext();

  const handleClick = () => {
    setImpersonatedUser(null);
    navigate('/user-list');
  };
  return (
    <>
      {impersonating && (
        <Wrapper>
          <p>You are currently impersonating {currentUser.name}.</p>
          <Button onClick={handleClick}>Stop impersonating</Button>
        </Wrapper>
      )}
    </>
  );
};

export default ImpersonationControl;

const Wrapper = styled.div`
  position: fixed;
  top: 1rem;
  left: calc(${(p) => p.theme.space.m} + 8rem);
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    left: calc(${(p) => p.theme.space.xl} + ${(p) => p.theme.space.chonky});
  }
  background: #eee;
  z-index: 1000;
  padding: 1rem;
  font-weight: 700;
  border-radius: 0;
  outline: 6px solid ${(p) => p.theme.bgColors.tertiary};
  display: flex;
  flex-direction: column;
`;
