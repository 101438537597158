import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { Main } from '../components/dumb/containers';
import { FullPageSpinner } from '../components/dumb/spinners';
import { ToastContainer } from '../components/smart/toasts';
import DevTools from '../dev-tools';
import loadDevTools from '../dev-tools/load';
import { useRouteDetails } from '../routes/useRouteDetails';
import ImpersonationControl from './admin/impersonation-control';
import Navigation from './navigation/navigation';
import { SkipNavLink } from './navigation/skip-nav-link';

const Layout = () => {
  const { currentRoute } = useRouteDetails();
  const useDevTools = loadDevTools();

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Helmet>
        <title>{currentRoute.details.title}</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Welcome to ${currentRoute.details.title}`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#7cb82f" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Helmet>
      <SkipNavLink />
      <ImpersonationControl />
      <Navigation />
      <Main id="main-content">
        <Outlet />
      </Main>
      <ToastContainer />
      {useDevTools && <DevTools />}
    </Suspense>
  );
};

export default Layout;
