import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { FullPageSpinner } from '../components/dumb/spinners';
import { defaultRequest } from './msalInstance';

/**
 * Forces Authentication to occur via MSAL before rendering the child components
 */
const ForceMsalAuthentication: React.FC = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (inProgress === 'none' && !isAuthenticated) {
      instance
        .acquireTokenRedirect(defaultRequest)
        .catch((e) => console.error(e));
    }
  }, [instance, inProgress, isAuthenticated]);

  return <>{isAuthenticated ? children : <FullPageSpinner />}</>;
};

export default ForceMsalAuthentication;
