import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import './assets/fonts/fonts.css';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  :root {
    --reactour-accent: ${(props) => props.theme.bgColors.secondary};
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  table, table * {
    border-radius: 0;
  }
  *:focus {
    outline: 3px solid ${(props) => props.theme.bgColors.primary};
    -moz-outline: 3px solid ${(props) => props.theme.bgColors.primary};
  }
  button:-moz-focusring, button[type="submit"]:-moz-focusring {
    outline: 3px solid ${(props) => props.theme.bgColors.primary};
  }

  html, body {
    height: 100%;
  }
  body {
    background: white;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Mark Pro', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-family: ${(props) => props.theme.fontFamilies.primary};
    font-size: 1.6rem;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: ${(props) => props.theme.fontColors.dark};
  }
  a, button {
    line-height: 1.6;
  }

  h1 {
    font-size: 2.6rem;
    font-weight: 700;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
      font-size: 4rem;
    }
  }
  h2 {
    font-size: 2.6rem;
    font-weight: 700;
  }
  h3 {
    font-size: 2.2rem;
    font-weight: 700;
  }
  h4 {
    font-size: 1.8rem;
    font-weight: 700;
  }

  ul li {
    padding: ${(props) => props.theme.space.xxs} 0;
  }

  a {
    color: ${(props) => props.theme.fontColors.dark};
    text-decoration-color: ${(props) => props.theme.bgColors.primary};
    &:hover {
      filter: brightness(90%);
      text-decoration-color: ${(props) => props.theme.bgColors.secondary};
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  fieldset {
    border: none;
    width: 100%;
    padding: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  legend {
    display: inline;
    font-weight: 700;
  }

  label {
    font-size: 100%;
    font-weight: 700;
    display: block;
  }
  input[type="checkbox"]+label,
  input[type="radio"]+label {
    font-weight: 400;
  }

  textarea, input, select {
    font-size: 100%;
    line-height: 1.4;
    font: ${(props) => props.theme.fontFamilies.primary};
    padding: ${(props) => props.theme.space.s};
    border: 2px solid ${(props) => props.theme.fontColors.lightGray};
    border-bottom: 2px solid ${(props) => props.theme.bgColors.dark};
    color: ${(props) => props.theme.fontColors.dark};
  }

  option:disabled {
    font-style: italic;
    color: ${(props) => props.theme.fontColors.darkGray};
    background: ${(props) => props.theme.bgColors.lightGray};
  }

  textarea {
    display: block;
    width: 100%;
    resize: none;
    overflow: auto;
    min-height: 10em;
  }

  input {
    display: block;
    width: 100%;
    height: 3em;
    :disabled {
      background-color: #ccc;
    }
  }

  input[type="checkbox"], input[type="radio"] {
    display: inline-block;
    width: auto;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    font-style: italic;
  }

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 0;
    * {
      border-radius: 0;
    }
    tr {
      clear: both;
      display: table-row;
    }
    th {
      font-weight: 700;
      text-align: left;
      background-color: ${(props) => props.theme.fontColors.dark};
      color: white;
      font-size: 90%;
    }
    th,
    td {
      padding: ${(props) => props.theme.space.s} ${(props) =>
  props.theme.space.l};
      border: 1px solid #ddd;
    }
    td {
      min-width: fit-content;
      font-size: 90%;
      tr:hover {
        background-color: ${(props) => props.theme.bgColors.lightGray};
      }
    }
  }

  table.zebra {
    tr:nth-child(even) {
      background-color: #f3f3f8;
    }
  }

  table.highlight-hover {
    tr {
      transition: background-color 0.08s ease;
    }
    tr:hover {
      background-color: #d7ebff;
    }
  }

  form {
    ::-moz-submit-invalid {
      box-shadow: none;
    }
    :not(output):-moz-ui-invalid {
      box-shadow: none;
    }
  }

  div:hover + label + [role="tooltip"],
  div:focus + label + [role="tooltip"],
  input:hover + label + [role="tooltip"],
  input:focus + label + [role="tooltip"] {
    display: block;
  }

  [data-reach-dialog-overlay] {
    z-index: 1001;
  }

  .svg-inline--fa {
    margin: 0 .25em;
  }

  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    white-space: nowrap;
  }

`;
