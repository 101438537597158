import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import appsettings from '../appsettings';
import getUserTokenData from '../authentication/api/getUserTokenData';
import { FullPageSpinner } from '../components/dumb/spinners';

export interface UserContextData {
  userId: string;
  fullName: string;
  email: string;
  roles: string[];
  branchManagerBranchCodes: string[];
  aadObjectId?: string;
}

const UserContext = React.createContext<UserContextData | null>(null);

const UserProvider: React.FC = (props) => {
  const { accounts } = useMsal();
  const {
    data = null,
    error,
    isRejected,
    isResolved,
    isSettled,
  } = useAsync({
    promiseFn: getUserTokenData,
  });

  return (
    <>
      {!isSettled && <FullPageSpinner />}

      {isRejected && (
        <div>
          <p>Uh Oh</p>
          {error && <code>{error.message}</code>}
        </div>
      )}

      {isResolved && (
        <UserContext.Provider
          value={
            data && {
              ...data,
              aadObjectId: accounts[0].localAccountId,
            }
          }
          {...props}
        />
      )}
    </>
  );
};

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  useEffect(() => {
    if (context) {
      if (appsettings.branchOverride.branchCode) {
        context.branchManagerBranchCodes[0] = appsettings.branchOverride.branchCode;
      }

      if (appsettings.rolesOverride) {
        context.roles = appsettings.rolesOverride;
      }
    }
  }, [context]);

  return context;
}

export { UserContext, UserProvider, useUser };
