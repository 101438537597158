import styled, { css } from 'styled-components';
import Breadcrumbs from '../../routes/breadcrumbs';

export const boxShadow = css`
  box-shadow: 0.25em 0.25em 0.25em ${(p) => p.theme.bgColors.dark}0D;
`;

export const Main = styled.main`
  padding-bottom: ${(p) => p.theme.space.xxl};
  padding-top: 8rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    padding-top: 0;
  }
`;

export const GridBase = styled.div`
  padding: ${(p) => p.theme.space.m};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    padding: ${(p) => p.theme.space.l} ${(p) => p.theme.space.xxl};
    margin-left: ${(p) => p.theme.space.chonky};
  }
  > * {
    margin: ${(p) => p.theme.space.xl} 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    > * {
      margin: 0;
    }
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: ${(p) => p.theme.space.xl};
  }
  .grid-6 {
    grid-column: span 6;
  }
  .grid-5 {
    grid-column: span 5;
  }
  .grid-4 {
    grid-column: span 4;
  }
  .grid-3 {
    grid-column: span 3;
  }
  .grid-2 {
    grid-column: span 2;
  }
  .grid-1 {
    grid-column: span 1;
  }
`;

export const GridContainer: React.FC = ({ children }) => (
  <GridBase>
    <Breadcrumbs />
    {children}
  </GridBase>
);

export const ExtendedGrid = styled(GridBase)`
  margin-left: 0;
  padding: 0;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.s}) {
    > * {
      margin: 0;
    }
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: ${(p) => p.theme.space.xl};
  }
`;

export const GridChild = styled.div<{
  defaultOrder?: number;
  desktopOrder?: number;
}>`
  padding: ${(p) => p.theme.space.l};
  background: ${(p) => p.theme.bgColors.lightGray};
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  order: ${(p) => p.defaultOrder ?? 0};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    order: ${(p) => p.desktopOrder ?? p.defaultOrder ?? 0};
  }
`;

export const FlexRow = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0 -${(p) => p.theme.space.m};

  > * {
    flex: 0 1 auto;
    margin: ${(p) => p.theme.space.m} 0 0 ${(p) => p.theme.space.m};
  }

  > h1,
  h2,
  h3,
  h4 {
    margin: 1.6rem 0 0 1.6rem;
  }

  &:first-child {
    margin-top: -${(p) => p.theme.space.m};
  }
  & ~ & {
    margin-top: 0;
  }
`;

export const AltFlexRow = styled(FlexRow)`
  > * {
    margin: ${(p) => p.theme.space.m} ${(p) => p.theme.space.m} 0 0;
  }
`;

export const HeaderFlexRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const NoVSpaceFlexRow = styled(FlexRow)`
  > * {
    margin: 0 0 0 ${(p) => p.theme.space.m};
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  > * {
    flex: 0 1 auto;
    margin-top: ${(p) => p.theme.space.s};
    margin-bottom: ${(p) => p.theme.space.s};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    margin: 0;
  }
`;

export const SpaceBetween = styled.div`
  > * {
    margin-top: ${(p) => p.theme.space.s};
    margin-bottom: ${(p) => p.theme.space.s};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const FloatContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;

  bottom: ${(p) => p.theme.space.s};
  width: calc(100% - ${(p) => p.theme.space.m}*2);

  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    bottom: ${(p) => p.theme.space.m};
    width: calc(100% - ${(p) => p.theme.space.xxl}*2);
  }
`;

export const EditorWrapper = styled.div`
  .editor-wrapper {
    display: block;
    padding-bottom: ${(p) => p.theme.space.chonky};
  }
  .editor-toolbar {
    border: 1px solid ${(p) => p.theme.fontColors.lightGray};
  }
  .editor {
    background: white;
    font-family: ${(p) => p.theme.fontFamilies.primary};
    padding: ${(p) => p.theme.space.s};
    border: 1px solid ${(p) => p.theme.fontColors.lightGray};
    border-bottom: 2px solid ${(p) => p.theme.bgColors.dark};
    color: ${(p) => p.theme.fontColors.dark};
  }
  .hide {
    display: none;
  }
`;

export const CalendarWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 75%;
`;
export const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CircleIconWrapper = styled.div`
  background-color: ${(p) => p.theme.bgColors.dark};
  color: white;
  border-radius: 50%;
  padding: ${(p) => p.theme.space.s};
  :hover,
  :focus {
    filter: none;
    background-color: ${(p) => p.theme.fontColors.secondary};
  }
`;
