import React, { useContext, useState } from 'react';
import { SmartLeadsSettings } from '../interfaces';

export const SmartLeadsSettingsContext = React.createContext<{
  settings: SmartLeadsSettings;
  setSettings: (s: SmartLeadsSettings) => void;
}>({
  settings: {
    smartLeadsEnabled: false,
    surefireEnabled: false,
    activeContactsCount: 0,
    contactsQuota: 0,
  },
  setSettings: (s) => null,
});

export function useSmartLeadsSettings() {
  return useContext(SmartLeadsSettingsContext);
}

export const SmartLeadsSettingsProvider: React.FC<{
  settings: SmartLeadsSettings;
}> = (props) => {
  const [settings, setSettings] = useState(props.settings);
  return (
    <SmartLeadsSettingsContext.Provider
      value={{
        settings: settings,
        setSettings: setSettings,
      }}
      {...props}
    />
  );
};
