import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: relative;
  &:hover {
    [role='tooltip'] {
      display: block;
    }
  }
`;

export const TooltipPopup = styled.div<{ right?: boolean; light?: boolean }>`
  display: none;
  position: absolute;
  z-index: 1;
  background: ${(p) => (p.light ? 'white' : p.theme.bgColors.darkGray)};
  color: ${(p) => (p.light ? p.theme.fontColors.dark : 'white')};
  border: ${(p) =>
    p.light ? `1px solid ${p.theme.bgColors.darkGray}` : 'none'};
  top: -${(p) => p.theme.space.s};
  left: ${(p) => !p.right && p.theme.space.xl};
  right: ${(p) => p.right && p.theme.space.xl};
  padding: 0.5em 1em;
`;
