import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useImpersonationContext } from '../context/impersonation-context';
import { useRouteDetails } from './useRouteDetails';

const Breadcrumbs = () => {
  const { impersonating } = useImpersonationContext();
  const { currentRouteBranch } = useRouteDetails();

  let filteredRoutes = impersonating
    ? currentRouteBranch.filter(
        (r) =>
          !r.details.excludeFromBreadcrumbs &&
          !r.details.excludeWhenImpersonating
      )
    : currentRouteBranch.filter((r) => !r.details.excludeFromBreadcrumbs);

  return (
    <>
      {filteredRoutes.length > 1 && (
        <div className="grid-6">
          {filteredRoutes.map((r, i) => (
            <span key={r.match.pathname}>
              {i === filteredRoutes.length - 1 ? (
                r.details.title ?? '..'
              ) : (
                <>
                  <NavLink to={r.match.pathname}>
                    {r.details.title ?? '..'}
                  </NavLink>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="xs"
                    style={{ margin: '0 .5em' }}
                  />
                </>
              )}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default Breadcrumbs;
