import React, { useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type Status = 'fulfilled' | 'rejected';

export interface ToastInput {
  status: Status;
  message: string;
}

export interface ToastOutput extends ToastInput {
  id: string;
}

export const ToastContext = React.createContext<{
  toasts: ToastOutput[];
  addToast: (t: ToastInput) => void;
  removeToast: (i: string) => void;
  message: string;
  setMessage: (m: string) => void;
}>({
  toasts: [],
  addToast: (t) => null,
  removeToast: (i) => null,
  message: '',
  setMessage: (m) => null,
});

export function useToast() {
  return useContext(ToastContext);
}

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastOutput[]>([]);
  const [message, setMessage] = useState('');

  const addToast = useCallback(
    (t: ToastInput) => {
      let id = uuidv4();
      setToasts((toasts) => [...toasts, { ...t, id: id }]);
      setMessage(t.message);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id: string) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider
      value={{ toasts, addToast, removeToast, message, setMessage }}
    >
      {children}
    </ToastContext.Provider>
  );
};
