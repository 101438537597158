import React, { useState } from 'react';
import { useAsync } from 'react-async';
import msalInstance, {
  defaultRequest,
} from '../../authentication/msalInstance';

const acquireToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    const token = await msalInstance.acquireTokenSilent({
      account: accounts[0],
      ...defaultRequest,
    });
    return token;
  }
};

const AuthTokenRetrieval: React.FC = () => {
  const { data: authToken, isResolved } = useAsync({
    promiseFn: acquireToken,
  });
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(authToken?.idToken ?? '');
    setCopied(true);
  };

  return (
    <>
      {isResolved && (
        <div>
          <label>
            ID Token: <input type="text" value={authToken?.idToken} readOnly />
          </label>
          <button onClick={copyToClipboard}>Copy</button>
          {copied && (
            <span style={{ color: 'green', marginLeft: '0.5em' }}>Copied!</span>
          )}{' '}
        </div>
      )}
    </>
  );
};

export default AuthTokenRetrieval;
