import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import ForceMsalAuthentication from '../authentication/ForceMsalAuthentication';
import msalInstance from '../authentication/msalInstance';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { novusTheme } from '../themes/novus-theme';
import { ImpersonationProvider } from './impersonation-context';
import { ToastProvider } from './toast-context';
import { UserProvider } from './user-context';
import { ViewportProvider } from './viewport-context';
import { reactPlugin } from '../AppInsights';

const AppProviders: React.FC = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <ForceMsalAuthentication>
          <UserProvider>
            <ViewportProvider>
              <ThemeProvider theme={novusTheme}>
                <ImpersonationProvider>
                  <ToastProvider>{children}</ToastProvider>
                </ImpersonationProvider>
              </ThemeProvider>
            </ViewportProvider>
          </UserProvider>
        </ForceMsalAuthentication>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
};

export default AppProviders;
