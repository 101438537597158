import AuthTokenRetrieval from './authTokenRetrieval';
import BranchManagerToggle from './branchManagerToggle';
import MigrationsTool from './migrationsTool';
import RoleOverride from './roles';
import TestMessageToggle from './testMessageToggle';

const tools = {
  TestMessageToggle,
  BranchManagerToggle,
  MigrationsTool,
  RoleOverride,
  AuthTokenRetrieval,
};

export default tools;
