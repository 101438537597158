// If you change the Role list here, also update:
// - infrastructure/azure-ad/app.tf and
// - hub-server/Hub.Data/Constants/Role.cs

enum Role {
  Default = 'Default',
  Administrator = 'Administrator',
  LoanDelivery = 'LoanDelivery',
  LoanOfficer = 'LoanOfficer',
  LOA = 'LOA',
  LoanProcessor = 'LoanProcessor',
  BranchManager = 'BranchManager',
  SalesManager = 'SalesManager',
  BranchSupport = 'BranchSupport',
  ContentAdmin = 'Content.Admin',
  OnboardingAdmin = 'Onboarding.Admin',
  OnboardingApprover = 'Onboarding.Approver',
  OnboardingManager = 'Onboarding.Manager',
  OnboardingHiringManager = 'Onboarding.Hiring.Manager',
  OnboardingReader = 'Onboarding.Reader',
  ProfileAdmin = 'Profile.Admin',
  PaymentsAdmin = 'Payments.Admin',
  SmartLeadsUser = 'SmartLeads.User',
  SmartLeadsAdmin = 'SmartLeads.Admin',
  SmartLeadsPartnersUser = 'SmartLeads.PartnersUser',
  MarketingAdmin = 'Marketing.Admin',
  MarketingUser = 'Marketing.User',
  TechnologySupport = 'TechnologySupport',
}

export default Role;
