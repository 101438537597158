import cx from 'classnames';
import React from 'react';
import { FlexRow } from '../components/dumb/containers';
import { reset } from './appsettings';
import './dev-tools.css';
import Tools from './tools';

const DevTools: React.FC = () => {
  const [impersonating, setImpersonating] = React.useState(false);
  const [alerts, setAlerts] = React.useState<string[]>([]);

  const toggleAlert = (name: string) => (active: boolean) =>
    active
      ? setAlerts([name, ...alerts])
      : setAlerts(alerts.filter((a) => a !== name));

  const onReset = () => {
    reset();
  };

  return (
    <div
      id="dev-tools"
      className={cx({
        alert: alerts.length > 0,
        impersonating,
      })}
    >
      <div>🛠</div>
      <div className="tools">
        <Tools.MigrationsTool setAlert={toggleAlert('MigrationsTool')} />
        <FlexRow style={{ alignItems: 'flex-start' }}>
          <Tools.BranchManagerToggle />
          <Tools.AuthTokenRetrieval />
          <Tools.RoleOverride setImpersonating={setImpersonating} />
          <button onClick={onReset}>Reset To Defaults</button>
        </FlexRow>
      </div>
    </div>
  );
};

export default DevTools;
