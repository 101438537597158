import client from '../../api/client';

interface UserTokenDataResponse {
  userId: string;
  fullName: string;
  email: string;
  roles: string[];
  branchManagerBranchCodes: string[];
}

function getUserTokenData(): Promise<UserTokenDataResponse> {
  return client('token');
}

export default getUserTokenData;
