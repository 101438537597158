import React from 'react';
import { Link } from 'react-router-dom';
import { GridContainer } from '../components/dumb/containers';
import { PageHeader } from '../components/dumb/typography';

const NoMatch: React.FC = () => {
  return (
    <>
      <PageHeader />
      <GridContainer>
        <Link className="grid-6" to="/">
          Return to the home page
        </Link>
      </GridContainer>
    </>
  );
};

export default NoMatch;
