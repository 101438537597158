import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import appsettings from '../appsettings';

const config: Configuration = {
  auth: {
    authority: appsettings.azureAd.authority,
    clientId: appsettings.azureAd.clientId,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const defaultRequest = {
  scopes: ['openid', 'profile'],
  forceRefresh: false,
};

const msalInstance = new PublicClientApplication(config);

export const acquireToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    const token = await msalInstance.acquireTokenSilent({
      account: accounts[0],
      ...defaultRequest,
    });
    return token;
  }
};

export default msalInstance;
