import React, { useContext, useState } from 'react';
import { useUser } from '../context/user-context';

interface User {
  userId?: string;
  name?: string;
}

const ImpersonationContext = React.createContext<{
  impersonating: boolean;
  currentUser: User;
  setImpersonatedUser: (u: User | null) => void;
}>({ impersonating: false, currentUser: {}, setImpersonatedUser: (u) => null });

const useImpersonationContext = () => useContext(ImpersonationContext);

const ImpersonationProvider: React.FC = ({ children }) => {
  const [impersonatedUser, setImpersonatedUser] = useState<User | null>(null);
  const user = useUser();

  return (
    <ImpersonationContext.Provider
      value={{
        impersonating: impersonatedUser != null,
        currentUser: impersonatedUser ?? {
          userId: user?.aadObjectId,
        },
        setImpersonatedUser: setImpersonatedUser,
      }}
    >
      {children}
    </ImpersonationContext.Provider>
  );
};

export { ImpersonationContext, useImpersonationContext, ImpersonationProvider };
