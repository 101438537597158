import {
  faAngleUp,
  faArrowLeft,
  faBars,
  faBell,
  faBroadcastTower,
  faChartLine,
  faClipboardList,
  faCloudMoon,
  faCloudSun,
  faComments,
  faCommentsDollar,
  faLaptopCode,
  faProjectDiagram,
  faTrophy,
  faUser,
  faUserFriends,
  faUsersCog,
  faDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniqueId from 'lodash/uniqueId';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useAuthorize } from '../../auth/authorize';
import Role from '../../auth/roles';
import { useImpersonationContext } from '../../context/impersonation-context';
import { useUser } from '../../context/user-context';
import { useViewport } from '../../context/viewport-context';
import * as Nav from './nav-components';

const Navigation: React.FC = () => {
  const authorize = useAuthorize();
  const user = useUser();
  const { impersonating, currentUser } = useImpersonationContext();

  const theme = useContext(ThemeContext);
  const { viewPortWidth, currentScroll } = useViewport();
  const [showMenu, setShowMenu] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const navBar = useRef<HTMLDivElement>(null);
  const navList = useRef<HTMLUListElement>(null);
  const toggleButton = useRef<HTMLButtonElement>(null);

  const navCurrent: any = navBar.current;
  const listCurrent: any = navList.current;

  // listen for escape key to close the menu
  useEffect(() => {
    if (showMenu) {
      window.addEventListener('keyup', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setShowMenu(false);
          navCurrent.querySelector('button').focus();
        }
      });
    } else {
      window.removeEventListener('keyup', (e: KeyboardEvent) =>
        setShowMenu(false)
      );
    }
  }, [navCurrent, showMenu]);

  // send focus to the first menu item
  useEffect(() => {
    if (showMenu) {
      const firstLink = listCurrent.querySelector('a');
      firstLink?.focus();
    }
  }, [listCurrent, showMenu]);

  const [buttonHeight, setButtonHeight] = useState(0);

  useEffect(() => {
    if (toggleButton.current !== null) {
      setButtonHeight(toggleButton.current.clientHeight);
    }
  }, []);

  const MenuListId = uniqueId();

  return (
    <>
      <Nav.NavWrapper ref={navBar}>
        <Nav.NavToggleButton
          data-testid="menu-btn"
          aria-haspopup="true"
          aria-controls={MenuListId}
          aria-expanded={showMenu}
          onClick={() => {
            showMenu ? setShowMenu(false) : setShowMenu(true);
          }}
          aria-label="Main navigation"
          ref={toggleButton}
        >
          {showMenu ? (
            <FontAwesomeIcon icon={faArrowLeft} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </Nav.NavToggleButton>
        <Nav.LogoLink
          to="/"
          aria-label="home"
          data-testid="nav-home"
          onClick={() => setShowMenu(false)}
          id="brand-logo"
        >
          <img
            src={
              viewPortWidth >= parseInt(theme.breakpoints.m.slice(0, -2))
                ? theme.logoWhite
                : theme.logo
            }
            alt={theme.name}
          />
        </Nav.LogoLink>
        <Nav.VerticalNav
          id={MenuListId}
          className={
            viewPortWidth >= parseInt(theme.breakpoints.m.slice(0, -2))
              ? ''
              : showMenu
              ? ''
              : 'menu-closed'
          }
          aria-hidden={!showMenu}
          buttonHeight={buttonHeight}
        >
          <Nav.NavUl ref={navList} buttonHeight={buttonHeight}>
            <Nav.NavLi>
              <Nav.NavIconExternalLink href="https://hub.novushomemortgage.com">
                <Nav.NavSvg icon={faProjectDiagram} size="4x" />
                Novus HUB
              </Nav.NavIconExternalLink>
            </Nav.NavLi>
            <Nav.NavLi>
            <Nav.NavIconExternalLink href="https://hub.novushomemortgage.com/support">
                <Nav.NavSvg icon={faProjectDiagram} size="4x" />
                NEED HELP?
              </Nav.NavIconExternalLink>
            </Nav.NavLi>
            <Nav.NavLi>
              <Nav.NavIconLink
                to={`/profile/${impersonating ? currentUser.userId : ''}`}
              >
                <Nav.NavSvg icon={faUser} size="4x" />
                My Profile
              </Nav.NavIconLink>
            </Nav.NavLi>
            <Nav.NavLi>
              <Nav.NavIconLink to={`/leaderboard`}>
                <Nav.NavSvg icon={faTrophy} size="4x" />
                Leaderboards
              </Nav.NavIconLink>
            </Nav.NavLi>
            <Nav.NavLi>
              <Nav.NavIconLink to="/notifications">
                <Nav.NavSvg icon={faBell} size="4x" />
                Notification Preferences
              </Nav.NavIconLink>
            </Nav.NavLi>
            {authorize({
              allowedRoles: [Role.ProfileAdmin],
            }) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/profile-admin">
                  <Nav.NavSvg icon={faUsersCog} size="4x" />
                  Profile Administration
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
            {authorize({ allowedRoles: [Role.PaymentsAdmin] }) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/payment-utilities">
                  <Nav.NavSvg icon={faDollar} size="4x" />
                  Payment Utilities
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
            {/* Temporarily disabled as of August 2024 */}
            {/* {authorize({
              allowedRoles: [Role.SmartLeadsUser],
            }) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/smartleads">
                  <Nav.NavSvg icon={faChartLine} size="4x" />
                  SmartLeads
                </Nav.NavIconLink>
              </Nav.NavLi>
            )} */}
            {authorize({
              allowedRoles: [Role.BranchSupport],
            }) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/forecasts">
                  <Nav.NavSvg icon={faCloudSun} size="4x" />
                  Company Forecasts
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
            {authorize({
              allowedRoles: [Role.BranchManager, Role.BranchSupport],
            }) &&
              (user?.branchManagerBranchCodes?.length === 1 &&
              !user?.roles.includes(Role.BranchSupport) ? (
                <>
                  <Nav.NavLi>
                    <Nav.NavIconLink
                      to={`forecasts/branches/history/${user?.branchManagerBranchCodes[0]}`}
                    >
                      <Nav.NavSvg icon={faCloudMoon} size="4x" />
                      My Forecast History
                    </Nav.NavIconLink>
                  </Nav.NavLi>
                  <Nav.NavLi>
                    <Nav.NavIconLink
                      to={`forecasts/branches/current/${user?.branchManagerBranchCodes[0]}`}
                    >
                      <Nav.NavSvg icon={faCloudSun} size="4x" />
                      My Current Forecast
                    </Nav.NavIconLink>
                  </Nav.NavLi>
                </>
              ) : (
                <>
                  <Nav.NavLi>
                    <Nav.NavIconLink to="/forecasts/branches/history">
                      <Nav.NavSvg icon={faCloudMoon} size="4x" />
                      {user?.roles.includes(Role.BranchManager) &&
                      !user?.roles.includes(Role.BranchSupport)
                        ? 'My '
                        : ''}
                      Forecast History
                    </Nav.NavIconLink>
                  </Nav.NavLi>
                  <Nav.NavLi>
                    <Nav.NavIconLink to="/forecasts/branches/current">
                      <Nav.NavSvg icon={faCloudSun} size="4x" />
                      {user?.roles.includes(Role.BranchManager) &&
                      !user?.roles.includes(Role.BranchSupport)
                        ? 'My '
                        : ''}
                      Current Forecasts
                    </Nav.NavIconLink>
                  </Nav.NavLi>
                </>
              ))}
            {authorize({
              allowedRoles: [
                Role.OnboardingAdmin,
                Role.OnboardingManager,
                Role.OnboardingHiringManager,
                Role.OnboardingApprover,
              ],
            }) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/onboarding">
                  <Nav.NavSvg icon={faClipboardList} size="4x" />
                  Onboarding Management
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
            {authorize({}) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/broadcasts">
                  <Nav.NavSvg icon={faBroadcastTower} size="4x" />
                  Emergency Broadcasts
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
            {authorize({
              allowedRoles: [
                Role.BranchSupport,
                Role.SmartLeadsAdmin,
                Role.ProfileAdmin,
              ],
            }) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/user-list">
                  <Nav.NavSvg icon={faUserFriends} size="4x" />
                  User List
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
            {authorize({}) && (
              <Nav.NavLi>
                <Nav.NavIconLink to="/admin">
                  <Nav.NavSvg icon={faLaptopCode} size="4x" />
                  Admin
                </Nav.NavIconLink>
              </Nav.NavLi>
            )}
          </Nav.NavUl>
        </Nav.VerticalNav>
      </Nav.NavWrapper>
      {currentScroll > 0 && (
        <Nav.BackToTopButton
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          aria-label="Back to top"
        >
          <FontAwesomeIcon icon={faAngleUp} size="2x" />
        </Nav.BackToTopButton>
      )}
    </>
  );
};

export default Navigation;
