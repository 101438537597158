import { isEqual } from 'lodash';
import { matchPath, Params } from 'react-router-dom';
import { routes } from '.';
import {
  DetailedRouteBranch,
  DetailedRouteMeta,
  DetailedRouteObject,
  DetailsData,
  Location,
} from './interfaces';

const joinPaths = (paths: string[]): string =>
  paths.join('/').replace(/\/\/+/g, '/');

const paramRe = /^:\w+$/;
const dynamicSegmentValue = 3;
const indexRouteValue = 2;
const emptySegmentValue = 1;
const staticSegmentValue = 10;
const splatPenalty = -2;
const isSplat = (s: string) => s === '*';

const computeScore = (path: string, index: boolean | undefined): number => {
  const segments = path.split('/');
  let initialScore = segments.length;
  if (segments.some(isSplat)) {
    initialScore += splatPenalty;
  }

  if (index) {
    initialScore += indexRouteValue;
  }

  return segments
    .filter((s) => !isSplat(s))
    .reduce((score, segment) => {
      if (paramRe.test(segment)) {
        return score + dynamicSegmentValue;
      }
      if (segment === '') {
        return score + emptySegmentValue;
      }
      return score + staticSegmentValue;
    }, initialScore);
};

const compareIndexes = (a: number[], b: number[]): number => {
  const siblings =
    a.length === b.length && a.slice(0, -1).every((n, i) => n === b[i]);
  return siblings ? a[a.length - 1] - b[b.length - 1] : 0;
};

const flattenRoutes = (
  routes: DetailedRouteObject[],
  branches: DetailedRouteBranch[] = [],
  parentsMeta: DetailedRouteMeta[] = [],
  parentPath = ''
): DetailedRouteBranch[] => {
  routes.forEach((route, index) => {
    if (
      typeof route.path !== 'string' &&
      !route.index &&
      !route.children?.length
    ) {
      throw new Error(
        'useRouteDetails: `path` or `index` must be provided in every route object'
      );
    }
    if (route.path && route.index) {
      throw new Error(
        'useRouteDetails: `path` and `index` cannot be provided at the same time'
      );
    }
    const meta: DetailedRouteMeta = {
      relativePath: route.path || '',
      childrenIndex: index,
      route,
    };

    if (meta.relativePath.charAt(0) === '/') {
      if (!meta.relativePath.startsWith(parentPath)) {
        throw new Error(
          'useRouteDetails: The absolute path of the child route must start with the parent path'
        );
      }
      meta.relativePath = meta.relativePath.slice(parentPath.length);
    }

    const path = joinPaths([parentPath, meta.relativePath]);
    const routesMeta = parentsMeta.concat(meta);

    if (route.children && route.children.length > 0) {
      if (route.index) {
        throw new Error(
          'useRouteDetails: Index route cannot have child routes'
        );
      }
      flattenRoutes(route.children, branches, routesMeta, path);
    } else {
      let duplicateBranch =
        branches.findIndex((branch) => branch.path === path) !== -1;

      if (!duplicateBranch) {
        branches.push({
          path,
          score: computeScore(path, route.index),
          routesMeta,
        });
      }
    }
  });

  return branches;
};

const rankRouteBranches = (
  branches: DetailedRouteBranch[]
): DetailedRouteBranch[] => {
  return branches.sort((a, b) =>
    a.score !== b.score
      ? b.score - a.score // Higher score first
      : compareIndexes(
          a.routesMeta.map((meta) => meta.childrenIndex),
          b.routesMeta.map((meta) => meta.childrenIndex)
        )
  );
};

const NO_DETAILS = Symbol('NO_DETAILS');

const getOneRouteDetailsMatch = ({
  pathSection,
  branches,
  location,
}: {
  pathSection: string;
  branches: DetailedRouteBranch[];
  location?: Location;
}): typeof NO_DETAILS | DetailsData => {
  let details: DetailsData | typeof NO_DETAILS | undefined;

  // Loop through the route array and see if the user has provided a details.
  branches.some(({ path, routesMeta }) => {
    const { route } = routesMeta[routesMeta.length - 1];
    let userProvidedDetails = route.details;

    const match = matchPath(
      {
        path,
        end: true,
      },
      pathSection
    );

    // If user passed details: null
    // we need to know NOT to add it to the matches array
    if (match && userProvidedDetails === null) {
      details = NO_DETAILS;
      return true;
    }

    if (match && userProvidedDetails) {
      details = {
        match: {
          params: match.params,
          pathname: match.pathname,
          route: route,
        },
        location: location,
        key: location?.key,
        details: userProvidedDetails,
      };
      return true;
    }
    return false;
  });

  // User provided a details prop
  if (details) {
    return details;
  }
  return NO_DETAILS;
};

const getOneRouteDetails = (
  pathname: string,
  branches: DetailedRouteBranch[],
  location?: Location
) => {
  let details: DetailsData[] = [];

  pathname
    .split('?')[0]
    .split('/')
    .reduce(
      (previousSection: string, currentSection: string, index: number) => {
        // Combine the last route section with the currentSection.
        // For example, `pathname = /1/2/3` results in match checks for
        // `/1`, `/1/2`, `/1/2/3`.
        const pathSection = !currentSection
          ? '/'
          : `${previousSection}/${currentSection}`;

        // Ignore trailing slash or double slashes in the URL
        if (pathSection === '/' && index !== 0) {
          return '';
        }

        const routeDetail = getOneRouteDetailsMatch({
          pathSection,
          branches,
          location,
        });

        // Add the details to the matches array
        if (routeDetail !== NO_DETAILS) {
          details.push(routeDetail);
        }

        return pathSection === '/' ? '' : pathSection;
      },
      ''
    );

  return details;
};

export const getAllRouteDetails = ({
  routes,
}: {
  routes: DetailedRouteObject[];
}): DetailsData[][] => {
  const branches = rankRouteBranches(flattenRoutes(routes));
  let detailBranches: DetailsData[][] = [];

  // Loop through the route array and see if the route has details.
  branches.forEach(({ routesMeta, path }) => {
    let details = getOneRouteDetails(path, branches);
    detailBranches.push(details);
  });

  return detailBranches;
};

export const getMatchingBranch = ({
  routeDetails,
  location,
  params,
}: {
  routeDetails: DetailsData[][];
  location?: Location;
  params?: Params<string>;
}) => {
  let notFound = routeDetails[routeDetails.length - 1];

  if (params) {
    let results =
      routeDetails.find((route) => {
        let routeParamKeys = Object.keys(
          route[route.length - 1].match.params ?? {}
        );
        if (routeParamKeys.length > 0) {
          return isEqual(routeParamKeys, Object.keys(params ?? {}));
        } else return false;
      }) ?? notFound;
    return results;
  }

  if (location) {
    let locationPath = location.pathname;

    let results: DetailsData[] = [];

    // special case for the home page - without this, the routing catches the Not Found route instead ('/*')
    if (locationPath === '/') {
      return [notFound[0]];
    }

    if (locationPath[locationPath.length - 1] === '/') {
      locationPath = locationPath.slice(0, -1);
    }

    results =
      routeDetails.find((route) => {
        return route[route.length - 1].match.pathname === locationPath;
      }) ?? notFound;

    return results;
  }

  return notFound;
};

// OLD

export const useCurrentRouteDetails = (location: Location): DetailsData[] => {
  const { pathname } = location;
  const branches = rankRouteBranches(flattenRoutes(routes));
  const details = getOneRouteDetails(pathname, branches, location);

  return details;
};
