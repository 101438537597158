import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BrightButton } from '../../components/dumb/buttons-links';

const NavWrapper = styled.div`
  width: 100%;
  height: 8rem;
  background: white;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    background: transparent;
    justify-content: flex-end;
    position: absolute;
  }
`;

const LogoLink = styled(Link)`
  height: 8rem;
  img {
    height: 100%;
  }
`;

const NavToggleButton = styled.button`
  padding: 2rem;
  width: 8rem;
  background-color: ${(p) => p.theme.navColors.primary};
  color: white;
  border: none;
  display: block;
  height: 100%;
  font-size: 2rem;
  line-height: 1;
  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.navColors.primaryHover};
    outline: none;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    display: none;
  }
`;

const VerticalNav = styled.nav<{
  buttonHeight: number;
}>`
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 8rem;
  height: calc(100vh - ${(p) => p.buttonHeight}px);
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    top: 0;
  }
  z-index: 100;

  transition-duration: 0.2s;
  transition-delay: 0.2s;
  transform: translate3d(0, 0, 0);
  &.menu-closed {
    transform: translate3d(-100%, 0, 0);
  }
`;

const NavUl = styled.ul<{
  buttonHeight: number;
}>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(p) => p.buttonHeight}px);
  background: ${(p) => p.theme.bgColors.darkGray};
`;

const NavLi = styled.li`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0;
  background: ${(p) => p.theme.bgColors.darkGray};
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  &:last-child {
    border-bottom: none;
  }
`;

const NavIconLink = styled(Link)`
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 4rem 1rem;
  border: none;
  position: relative;
  display: block;
  width: ${(p) => p.theme.space.chonky};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.bgColors.primary};
    outline: none;
  }
`;

const NavIconExternalLink = styled.a`
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 4rem 1rem;
  border: none;
  position: relative;
  display: block;
  width: ${(p) => p.theme.space.chonky};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.bgColors.primary};
    outline: none;
  }
`;

const NavIconButton = styled.button`
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 4rem 1rem;
  border: none;
  position: relative;
  display: block;
  width: ${(p) => p.theme.space.chonky};
  &:hover,
  &:focus {
    background: ${(p) => p.theme.bgColors.primary};
    outline: none;
  }
  background: transparent;
`;

const NavSvg = styled(FontAwesomeIcon)`
  left: 0;
  top: 25%;
  width: 100% !important;
  display: block;
  position: absolute;
  opacity: 0.1;
  stroke: currentColor;
  fill: currentColor;
  &.svg-inline--fa {
    margin: 0 auto;
  }
`;

const BackToTopButton = styled(BrightButton)`
  position: fixed;
  bottom: ${(p) => p.theme.space.m};
  right: ${(p) => p.theme.space.m};
  background: ${(p) => p.theme.bgColors.primary};
  border-radius: 50%;
  width: ${(p) => p.theme.space.xxl};
  height: ${(p) => p.theme.space.xxl};
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export {
  NavWrapper,
  LogoLink,
  NavToggleButton,
  VerticalNav,
  NavUl,
  NavLi,
  NavIconLink,
  NavIconExternalLink,
  NavIconButton,
  NavSvg,
  BackToTopButton,
};
