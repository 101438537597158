import loset from 'lodash/set';
import appsettings from '../appsettings';

const key = 'hub.settings';

// update settings with what's in localStorage
try {
  Object.assign(
    appsettings,
    JSON.parse(window.localStorage.getItem(key) || '{}')
  );
} catch (error) {
  window.localStorage.removeItem(key);
}

function set(name: string, value: any) {
  loset(appsettings, name, value);
  window.localStorage.setItem(key, JSON.stringify(appsettings));
}

function reset() {
  window.localStorage.removeItem(key);
}

export default appsettings;
export { set, reset };
