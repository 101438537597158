import appsettings from './appsettings';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
var reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: appsettings.appInsights.key,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin as any],
    }
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };