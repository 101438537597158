import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '../../context/toast-context';
import { SubtleButton } from '../dumb/buttons-links';

const CopyToClipboardButton: React.FC<{
  value?: string;
}> = ({ value }) => {
  const { addToast } = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value ?? '');
    addToast({
      status: 'fulfilled',
      message: `Copied to clipboard`,
    });
  };

  return (
    <SubtleButton
      onClick={copyToClipboard}
      type="button"
      aria-label="copy to clipboard"
    >
      <FontAwesomeIcon icon={faClipboard} />
    </SubtleButton>
  );
};

export default CopyToClipboardButton;
