import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import bgImg from '../../assets/NovusBG.jpg';
import { useRouteDetails } from '../../routes/useRouteDetails';
import CopyToClipboardButton from '../smart/copyToClipboard';

export const PageHeader: React.FC<{
  style?: React.CSSProperties;
}> = (p) => {
  const headingRef = useRef<HTMLHeadingElement>(null);

  const { currentRoute } = useRouteDetails();
  let routeTitle = currentRoute?.details.title ?? '';

  useEffect(() => {
    if (headingRef && headingRef.current) {
      headingRef.current.focus();
    }
  }, [headingRef]);

  return (
    <>
      <Helmet>
        <title>{routeTitle}</title>
      </Helmet>
      <HeroWrapper>
        <StyledPageHeader
          tabIndex={-1}
          ref={headingRef}
          data-tour="page-header"
          {...p}
        >
          {routeTitle}
        </StyledPageHeader>
      </HeroWrapper>
    </>
  );
};

const HeroWrapper = styled.div`
  background: ${(p) => p.theme.bgColors.dark} url(${bgImg}) no-repeat 100% /
    cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: ${(p) => p.theme.space.xl};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    margin-left: ${(p) => p.theme.space.chonky};
    padding: ${(p) => p.theme.space.xxl};
  }
`;

export const StyledPageHeader = styled.h1`
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  :focus {
    outline: none;
  }
`;

export const SubheadParagraph = styled.p`
  color: ${(p) => p.theme.fontColors.primary};
  text-transform: uppercase;
  margin-top: 0;
`;

export const SectionHeader = styled.h2`
  color: ${(p) => p.theme.fontColors.dark};
`;

export const SubHeader = styled.h3`
  color: ${(p) => p.theme.fontColors.primary};
`;

export const Details = styled.p`
  font-style: italic;
  font-size: 90%;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Alert = styled.span`
  color: ${(p) => p.theme.fontColors.warn};
`;

export const PaddedSpan = styled.span`
  padding: ${(p) => p.theme.space.xxs} ${(p) => p.theme.space.xs};
`;

export const Badge = styled(PaddedSpan)`
  font-weight: 700;
  background: rgba(205, 92, 92, 0.3);
  border: 1px solid rgba(205, 92, 92, 0.3);
  border-radius: 1px;
`;

export const SmartSpan: React.FC<{ alert: boolean }> = ({
  alert,
  children,
}) => (
  <>{alert ? <Badge>{children}</Badge> : <PaddedSpan>{children}</PaddedSpan>}</>
);

export const LabelDiv = styled.div`
  font-size: 90%;
  margin-right: 1em;
`;

export const NoBulletUl = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const FakeLabel = styled.span`
  font-size: 100%;
  font-weight: 700;
  margin-right: 1em;
  display: block;
`;

export const Detail: React.FC<{
  label: string;
  description?: string;
  value?: string;
  className?: string;
  testId?: string;
  hasClipboard?: boolean;
}> = ({ label, description, value, className, hasClipboard, testId }) => (
  <p className={className ?? 'grid-3'}>
    {hasClipboard && <CopyToClipboardButton value={value} />}
    <strong>{label}</strong>: <span data-testid={testId}>{value}</span>
    {description && <Description>{description}</Description>}
  </p>
);

export const Description = styled.small`
  font-style: italic;
  display: block;
`;
