import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import AppProviders from './context';
import { GlobalStyle } from './global-style';
import { routes } from './routes';
import * as serviceWorker from './serviceWorker';

const Routes = () => {
  let elements = useRoutes(routes);
  return elements;
};

const App = () => {
  return (
    <AppProviders>
      <GlobalStyle />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AppProviders>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
