import {
  faBan,
  faPen,
  faSave,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisuallyHidden } from '@reach/visually-hidden';
import React from 'react';
import { AsyncState } from 'react-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexRow } from './containers';
import { FastSpinningIcon } from './spinners';

export const Button = styled.button`
  font-weight: 700;
  padding: ${(p) => p.theme.space.m};
  background-color: ${(p) => p.theme.bgColors.dark};
  cursor: pointer;
  color: white;
  border: none;
  font-family: ${(p) => p.theme.fontFamilies.primary};
  line-height: 1.15;
  display: block;
  :hover {
    filter: brightness(80%);
  }
  :disabled {
    filter: brightness(70%);
    :hover {
      filter: brightness(70%);
      cursor: default;
    }
  }
`;

export const ButtonLink = styled(Link)`
  font-weight: 700;
  padding: ${(p) => p.theme.space.m};
  background-color: ${(p) => p.theme.bgColors.dark};
  cursor: pointer;
  color: white;
  border: none;
  text-decoration: none;
  font-family: ${(p) => p.theme.fontFamilies.primary};
  line-height: 1.15;
  display: inline-block;
  :focus {
    outline-color: ${(p) => p.theme.bgColors.secondary};
  }
  :hover {
    filter: brightness(80%);
    text-decoration: none;
  }
  :disabled {
    filter: brightness(70%);
    :hover {
      filter: brightness(70%);
      cursor: default;
    }
  }
`;

export const ButtonA = styled.a`
  font-weight: 700;
  padding: ${(p) => p.theme.space.m};
  background-color: ${(p) => p.theme.bgColors.dark};
  cursor: pointer;
  color: white;
  border: none;
  text-decoration: none;
  font-family: ${(p) => p.theme.fontFamilies.primary};
  line-height: 1.15;
  display: block;
  :focus {
    outline-color: ${(p) => p.theme.bgColors.secondary};
  }
  :hover {
    filter: brightness(80%);
    text-decoration: none;
  }
  :disabled {
    filter: brightness(70%);
    :hover {
      filter: brightness(70%);
      cursor: default;
    }
  }
`;

export const BrightButton = styled(Button)`
  background-color: ${(p) => p.theme.bgColors.primary};
  :focus {
    outline-color: ${(p) => p.theme.bgColors.dark};
  }
`;

export const BrightButtonLink = styled(ButtonLink)`
  background-color: ${(p) => p.theme.bgColors.primary};
  :focus {
    outline-color: ${(p) => p.theme.bgColors.dark};
  }
`;

export const BrightButtonA = styled(ButtonA)`
  background-color: ${(p) => p.theme.bgColors.primary};
  :focus {
    outline-color: ${(p) => p.theme.bgColors.dark};
  }
`;

export const AltBrightButton = styled(BrightButton)`
  background-color: ${(p) => p.theme.bgColors.secondary};
`;

export const WhiteButton = styled(Button)`
  background-color: white;
  color: ${(p) => p.theme.fontColors.dark};
  padding: ${(p) => p.theme.space.m} - 2px;
  outline: 2px solid ${(p) => p.theme.bgColors.secondary};
  :focus {
    outline: 3px solid ${(p) => p.theme.bgColors.primary};
  }
`;

export const SubtleButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  padding: ${(p) => p.theme.space.xs} 0;
  text-align: left;
  :hover,
  :focus {
    color: ${(p) => p.theme.fontColors.primary};
    cursor: pointer;
  }
  :disabled {
    text-decoration: none;
    opacity: 0.5;
    cursor: default;
    :hover {
      color: inherit;
    }
  }
`;

export const WarnBrightButton = styled(BrightButton)`
  background-color: ${(p) => p.theme.bgColors.tertiary};
`;

export const SubtleWarnButton = styled(SubtleButton)`
  color: ${(p) => p.theme.fontColors.warn};
  :hover,
  :focus {
    text-decoration-color: ${(p) => p.theme.fontColors.dark};
    color: ${(p) => p.theme.fontColors.dark};
  }
`;

export const WarnLink = styled(Link)`
  color: ${(p) => p.theme.fontColors.warn};
  text-decoration-color: ${(p) => p.theme.fontColors.warn};
  &:hover {
    filter: brightness(90%);
    text-decoration-color: ${(p) => p.theme.fontColors.warn};
  }
`;

export const StyledCloseButton = styled(Button)`
  border: none;
  background: transparent;
  padding: 0;
  color: ${(p) => p.theme.fontColors.dark};
  :hover,
  :focus {
    filter: brightness(100%);
    color: ${(p) => p.theme.fontColors.primary};
    cursor: pointer;
  }
`;

export const CloseButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (
  p
) => (
  <StyledCloseButton {...p}>
    <VisuallyHidden>Close</VisuallyHidden>
    <FontAwesomeIcon icon={faTimes} size="2x" />
  </StyledCloseButton>
);

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const DeleteIconButton = styled(IconButton)`
  :hover,
  :focus {
    filter: brightness(50%);
  }
`;

export const IconEditSaveCancel: React.FC<{
  handleEdit: () => void;
  handleSave: () => void;
  handleCancel: () => void;
  isEditing: boolean;
  postState: AsyncState<any>;
  targetId: string;
  targetName: string;
}> = ({
  handleEdit,
  handleSave,
  handleCancel,
  isEditing,
  postState,
  targetId,
  targetName,
  ...p
}) => (
  <>
    {postState.isPending && <FastSpinningIcon icon={faSpinner} />}
    {!postState.isPending && isEditing ? (
      <>
        <SubtleButton
          onClick={handleSave}
          aria-label={`Save forecast for ${targetName}`}
          data-testid={`save-${targetId}`}
          {...p}
        >
          <FontAwesomeIcon icon={faSave} />
        </SubtleButton>
        <SubtleButton
          onClick={handleCancel}
          aria-label={`Cancel`}
          data-testid={`cancel-${targetId}`}
          {...p}
        >
          <FontAwesomeIcon icon={faBan} />
        </SubtleButton>
      </>
    ) : (
      <SubtleButton
        onClick={handleEdit}
        aria-label={`Edit forecast for ${targetName}`}
        data-testid={`edit-${targetId}`}
        {...p}
      >
        <FontAwesomeIcon icon={faPen} />
      </SubtleButton>
    )}
  </>
);

export const ButtonRow = styled(FlexRow)`
  margin-top: ${(p) => p.theme.space.xl};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.m}) {
    margin-top: ${(p) => p.theme.space.xl};
  }
`;

export const ToggleButton = styled(Button)<{ pressed: boolean }>`
  background: ${(p) => (p.pressed ? 'white' : p.theme.bgColors.medGray)};
  color: ${(p) => (p.pressed ? p.theme.fontColors.dark : '#555')};
  border: 2px solid ${(p) => p.theme.bgColors.medGray};
  display: inline;
  padding: calc(${(p) => p.theme.space.m} - 2px);
  :first-of-type {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }
  :last-of-type {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
  }
`;
