/* eslint-disable jsx-a11y/no-onchange */
import { uniqueId } from 'lodash';
import React from 'react';
import Role from '../../auth/roles';
import { StyledSelect } from '../../components/dumb/inputs';
import appsettings, { set } from '../appsettings';

const RoleOverride: React.FC<{
  setImpersonating: (active: boolean) => void;
}> = ({ setImpersonating }) => {
  const [overrideRoles, setOverrideRoles] = React.useState(
    appsettings.rolesOverride
  );

  React.useEffect(() => {
    set('rolesOverride', overrideRoles);
    setImpersonating(overrideRoles != null && overrideRoles.length > 0);
  }, [overrideRoles, setImpersonating]);

  const selectId = uniqueId();

  return (
    <div>
      <label htmlFor={selectId}>Roles: </label>
      <StyledSelect
        style={{ display: 'block' }}
        id={selectId}
        value={(overrideRoles as string[]) ?? []}
        onChange={(x) =>
          setOverrideRoles(
            Array.from(x.target.selectedOptions).map((o) => o.value as Role)
          )
        }
        multiple
      >
        {Object.entries(Role).map((r) => (
          <option key={r[1]} value={r[1]}>
            {r[0]}
          </option>
        ))}
      </StyledSelect>
      <button onClick={() => setOverrideRoles(null)}>Clear Roles</button>
    </div>
  );
};

export default RoleOverride;
