import Role from './auth/roles';

declare global {
  interface Window {
    HUB_SETTINGS: AppSettings;
  }
}

interface AppSettings {
  azureAd: {
    authority: string;
    clientId: string;
  };
  testBranches: {
    hideTestBranches: boolean;
  };
  branchOverride: {
    branchCode: string;
  };
  rolesOverride: Role[] | null | undefined;
  toggles: {
    printTestMessage: boolean;
  };
  flatfile: {
    licenseKey: string;
    devMode: boolean;
  };
  appInsights: {
    key: string;
  };
}

if (!window.HUB_SETTINGS && process.env.NODE_ENV === 'development') {
  throw new Error(
    'Failed to find HUB_SETTINGS; Ensure you have the correct API URL configured and the backend is running correctly.'
  );
} else if (!window.HUB_SETTINGS) {
  throw new Error(
    'Failed to load site settings; Please refresh or try again later.'
  );
}

export default window.HUB_SETTINGS || { toggles: {} };
