import { createContext, useContext, useReducer } from 'react';
import { useLocation, useParams } from 'react-router';
import { Location, Params } from 'react-router-dom';
import { routes } from '.';
import { DetailsData } from './interfaces';
import { getAllRouteDetails, getMatchingBranch } from './utils';

type RouteDetailsType = {
  routeDetails: DetailsData[][];
  updateRouteDetails: (u: {
    details: DetailsData[];
    params?: Params<string>;
    location?: Location;
  }) => void;
  currentRouteBranch: DetailsData[];
  currentRoute: DetailsData;
};

const RouteDetailsContext = createContext<RouteDetailsType>({
  routeDetails: [],
  updateRouteDetails: (u) => null,
  currentRouteBranch: [],
  currentRoute: {
    match: {
      pathname: '',
    },
    details: {},
  },
});

export const useRouteDetails = () => {
  return useContext(RouteDetailsContext);
};

const routeDetailsReducer = (
  state: DetailsData[][],
  update: {
    details: DetailsData[];
    params?: Params<string>;
    location?: Location;
  }
) => {
  const matchingBranch = getMatchingBranch({
    routeDetails: state,
    params: update.params,
    location: update.location,
  });

  if (matchingBranch) {
    const index = state.indexOf(matchingBranch);
    state[index] = update.details;
  }
  return [...state];
};

export const RouteDetailsProvider: React.FC = ({ children }) => {
  const [routeDetails, updateRouteDetails] = useReducer(
    routeDetailsReducer,
    getAllRouteDetails({
      routes: routes,
    })
  );

  const location = useLocation();
  const params = useParams();

  let currentRouteBranch = getMatchingBranch({
    routeDetails: routeDetails,
    params: Object.keys(params).length > 0 ? params : undefined,
    location: location,
  });
  let currentRoute = currentRouteBranch[currentRouteBranch.length - 1];

  return (
    <RouteDetailsContext.Provider
      value={{
        routeDetails: routeDetails,
        updateRouteDetails: updateRouteDetails,
        currentRouteBranch: currentRouteBranch,
        currentRoute: currentRoute,
      }}
    >
      {children}
    </RouteDetailsContext.Provider>
  );
};
