import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

export const SpinContainer = styled.div`
  height: 100vh;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const spin = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export const SlowSpinningIcon = styled(FontAwesomeIcon)`
  animation: ${spin} 30s linear infinite;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const FastSpinningIcon = styled(FontAwesomeIcon)`
  animation: ${spin} 2s linear infinite;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export function FullPageSpinner() {
  const spinRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (spinRef && spinRef.current) {
      spinRef.current.focus();
    }
  }, [spinRef]);

  return (
    <SpinContainer>
      <FastSpinningIcon icon={faSpinner} size="3x" />
      <div
        style={{
          position: 'absolute',
          height: '1px',
          width: '1px',
          clip: 'rect(1px, 1px, 1px, 1px)',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        tabIndex={-1}
        ref={spinRef}
      >
        Loading
      </div>
    </SpinContainer>
  );
}
