import axios, { AxiosRequestConfig } from 'axios';
import appsettings from '../appsettings';
import { acquireToken } from '../authentication/msalInstance';

const client = async (
  endpoint: string,
  customConfig: AxiosRequestConfig = {}
) => {
  const headers: Record<string, string> = {};

  const token = await acquireToken();
  if (token) headers['authorization'] = `Bearer ${token.idToken}`;

  // Some dev tooling--the server will pretend you have different roles
  // if you're an admin and this header is present
  if (appsettings.rolesOverride) {
    headers['x-nhm-dev-roles'] = appsettings.rolesOverride.join(',');
  }

  const config: AxiosRequestConfig = {
    withCredentials: true,
    method: customConfig.data ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  return await axios(
    `${process.env.REACT_APP_API_URL || ''}/api/${endpoint}`,
    config
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        throw Error('Invalid username or password.');
      } else {
        throw Error(`${error.response.data.detail}`);
      }
    });
};

export default client;
